import React, { useContext, useEffect, useState } from "react";
import { Dark } from "../../../Theme/Theme";
import SocketContext from "../../../Healer/SocketContect";
import moment from "moment";
import { toast } from "react-toast";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";

let ratebid = 0;
let rateask = 0;

export default function WatchlistCard(props) {
  const i = props.item;

  const socket = useContext(SocketContext);

  const [chng, setchng] = useState(0);
  const [percent, setpercent] = useState(0);
  const [ask, setask] = useState(0);
  const [bid, setbid] = useState(0);
  const [high, sethigh] = useState(0);
  const [low, setlow] = useState(0);
  const [ltp, setltp] = useState(0);
  const [open, setOpne] = useState(0);
  const [close, setClose] = useState(0);

  const [bidcolor, setbidcolor] = useState("");
  const [askcolor, setaskcolor] = useState("");

  const [added, setAdded] = useState(false);

  const trade = (msg) => {
    if (msg != null) {

      console.log(bid,"bid")

      if (bid !== msg.Bid) {
        if (msg.Bid > 0) {
          setbid(msg.Bid);
          if (ratebid > msg.Bid) {
            setbidcolor(Dark.buy);
          } else {
            setbidcolor(Dark.sell);
          }
          ratebid = msg.Bid;
        }
      }
      if (ask !== msg.Ask) {
        if (msg.Ask > 0) {
          setask(msg.Ask);
          if (rateask > msg.Ask) {
            setaskcolor(Dark.buy);
          } else {
            setaskcolor(Dark.sell);
          }
          rateask = msg.Ask;
        }
      }
      if (high !== msg.High) {
        sethigh(msg.High);
      }
      if (low !== msg.Low) {
        setlow(msg.Low);
      }
      if (open !== msg.Open) {
        setOpne(msg.Low);
      }
      if (close !== msg.Previous_Close) {
        setClose(msg.Previous_Close);
      }
      if (msg.LTP - msg.Previous_Close !== chng) {
        setchng(msg.LTP - msg.Previous_Close);
        setpercent(((msg.LTP - msg.Previous_Close) / msg.Previous_Close) * 100);
      }
      if (ltp !== msg.LTP) {
        setltp(msg.LTP);
      }
    }
  };
  const bidask = (msg) => {
    if (msg != null) {
      if (bid !== msg.Bid) {
        if (msg.Ask > 0) {
          setbid(msg.Bid);
          if (ratebid > msg.Bid) {
            setbidcolor(Dark.buy);
          } else {
            setbidcolor(Dark.sell);
          }
          ratebid = msg.Bid;
        }
      }
      if (ask !== msg.Ask) {
        if (msg.Ask > 0) {
          setask(msg.Ask);
          if (rateask > msg.Ask) {
            setaskcolor(Dark.buy);
          } else {
            setaskcolor(Dark.sell);
          }
          rateask = msg.Ask;
        }
      }
    }
  };

  useEffect(() => {
    checkAdd();
    socket.emit("giverate", i.symbol_id);
    socket.on("trade" + i.symbol_id, trade);
    socket.on("bidask" + i.symbol_id, bidask);
    return () => {
      socket.off("trade" + i.symbol_id, trade);
      socket.off("bidask" + i.symbol_id, bidask);
    };
  }, [added]);

  const addFavorite = () => {
    let arry = localStorage.getItem("favorite");

    let newarry = [];

    if (arry == null) {
      setAdded(true);
      newarry.push(i);
      localStorage.setItem("favorite", JSON.stringify(newarry));
    } else {
      let check = JSON.parse(arry).filter((x) => x.symbol_id == i.symbol_id);

      if (check.length > 0) {
        toast.error("AllReady Added", {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      } else {
        setAdded(true);
        let arryadd = JSON.parse(arry);

        arryadd.push(i);

        localStorage.setItem("favorite", JSON.stringify(arryadd));
      }
    }
  };

  const checkAdd = () => {
    let arry = localStorage.getItem("favorite");

    if (arry == null) {
    } else {
      let check = JSON.parse(arry).filter((x) => x.symbol_id == i.symbol_id);

      if (check.length > 0) {
        setAdded(true);
      }
    }
  };

  const Remmove = () => {
    let arry = localStorage.getItem("favorite");

    if (arry == null) {
    } else {
      let value = i.symbol_id;

      let newarr = JSON.parse(arry).filter((item) => item.symbol_id !== value);

      localStorage.setItem("favorite", JSON.stringify(newarr));
      setAdded(false)
    }
  };

  return (
    <div
      key={added}
      style={{
        margin: 10,
        backgroundColor: Dark.background,
        height: "140px",
        width: "260px",
        borderRadius: 10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          padding: 5,
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: 12,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.buy,
          }}
        >
          OPEN : {open}
        </div>
        <div
          style={{
            fontSize: 12,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.sell,
          }}
        >
          CLOSE : {close}
        </div>
        <div
          onClick={() => (added ? Remmove() : addFavorite())}
          style={{
            fontSize: 12,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {added ? (
            <MdFavorite size={22} color={Dark.sell} />
          ) : (
            <MdFavoriteBorder size={22} color={Dark.primary} />
          )}
        </div>
      </div>
      <div
        onClick={() => props.open(i)}
        style={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        <div style={{ display: "flex", fontSize: 20, color: Dark.text }}>
          {i.symbol_display}{" "}
          <div style={{ fontSize: 10, paddingTop: 7, paddingLeft: 5 }}>
            {moment(i.expiry_date).format("DD-MMM")}
          </div>
        </div>
        <div style={{ display: "flex" }}>
          <div style={{ paddingTop: 7, color: Dark.text, fontSize: 16 }}>
            LTP : {ltp}
          </div>
          <div
            style={{
              paddingTop: 8,
              color: chng > 0 ? Dark.buy : Dark.sell,
              fontSize: 14,
              paddingLeft: 5,
            }}
          >
            {parseFloat(chng).toFixed(2)}
            {"  "}
            {"(" + parseFloat(percent).toFixed(2) + "%)"}
          </div>
        </div>

        <div
          style={{
            paddingTop: 7,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: bidcolor == "" ? Dark.secondary : bidcolor,
              marginRight: 5,
              marginLeft: 5,
              borderRadius: 7,
              paddingLeft: 5,
              paddingRight: 5,
              color: Dark.text,
              paddingBottom: 5,
              paddingTop: 5,
            }}
          >
            BID : {bid}
          </div>
          <div
            style={{
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: askcolor == "" ? Dark.secondary : askcolor,
              marginRight: 5,
              marginLeft: 5,
              borderRadius: 7,
              paddingLeft: 5,
              paddingRight: 5,
              color: Dark.text,
              paddingBottom: 5,
              paddingTop: 5,
            }}
          >
            ASK : {ask}
          </div>
        </div>
      </div>
      <div
        onClick={() => props.open(i)}
        style={{
          padding: 5,
          display: "flex",
          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            fontSize: 12,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.buy,
          }}
        >
          HIGH : {high}
        </div>
        <div
          style={{
            fontSize: 12,
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            color: Dark.sell,
          }}
        >
          LOW : {low}
        </div>
      </div>
    </div>
  );
}
