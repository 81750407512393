// import React, { useEffect, useState } from "react";
// import { InputText } from "primereact/inputtext";
// import { Dark } from "../../../../Theme/Theme";
// import { RiFileList3Line } from "react-icons/ri";
// import { IoMdAddCircle } from "react-icons/io";
// import { BiFilterAlt } from "react-icons/bi";
// import { BsFilePdf } from "react-icons/bs";
// import Backend from "../../../../Backend/Backend";
// import { toast } from "react-toast";
// import ClientCard from "./ClientCard";
// import "./Client.css";
// import Loading from "react-loading";
// import { PDFDownloadLink } from "@react-pdf/renderer";
// import ClientPdf from "./ClientPdf/ClientPdf";
// import { VirtualScroller } from "primereact/virtualscroller";
// import DataTable from "react-data-table-component";
// import styled from "styled-components";

// const backend = new Backend();

// const TextField = styled.input`
//   height: 32px;
//   width: 200px;
//   border-radius: 3px;
//   border-top-left-radius: 5px;
//   border-bottom-left-radius: 5px;
//   border-top-right-radius: 0;
//   border-bottom-right-radius: 0;
//   border: 1px solid #e5e5e5;
//   padding: 0 32px 0 16px;

//   &:hover {
//     cursor: pointer;
//   }
// `;

// const ClearButton = styled.button`
//   border-top-left-radius: 0;
//   border-bottom-left-radius: 0;
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;
//   height: 34px;
//   width: 32px;
//   text-align: center;
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// export default function Client(props) {
//   const [search, setSearch] = useState("");
// const [loading, setLoading] = useState("");
// const [clientlist, setClientList] = useState([]);
// const [clientsearch, setClientSearch] = useState([]);

//   const [keys, setkey] = useState(false);

//   const [filterText, setFilterText] = React.useState("");
//   const [resetPaginationToggle, setResetPaginationToggle] =
//     React.useState(false);
//   const filteredItems = clientlist.filter(
//     (item) =>
//       item.username &&
//       item.username.toLowerCase().includes(filterText.toLowerCase())
//   );

// useEffect(() => {
//   loadClient();
// }, [keys]);

// const loadClient = () => {
//   setLoading(true);
//   let data = {
//     token: localStorage.getItem("token"),
//     id: localStorage.getItem("id"),
//     username: localStorage.getItem("username"),
//     server: localStorage.getItem("server"),
//   };

//   backend.load_client(data).then((r) => {
//     setLoading(false);
//     if (r.error == "False") {
//       // var clients = [];
//       // if (r.client.length > 0) {
//       //   r.client.map((i, t) => {
//       //     if (t < 100) {
//       //       clients.push(i);
//       //     }
//       //   });
//       // }

//       setClientList(r.client);
//       setClientSearch(r.client);

//       console.log(r.client.length, "setClientList");
//     } else {
//       toast.error(r.message, {
//         backgroundColor: Dark.sell,
//         color: Dark.text,
//       });
//     }
//   });
// };

//   // const loadPermission = () => {
//   //   let data = {
//   //     token: localStorage.getItem("token"),
//   //     id: localStorage.getItem("id"),
//   //     username: localStorage.getItem("username"),
//   //     server: localStorage.getItem("server"),
//   //   };

//   //   backend.permission(data).then((r) => {
//   //     if (r.error == "False") {
//   //       setPermission(r.permission);
//   //     } else {
//   //       toast.error(r.message, {
//   //         backgroundColor: Dark.buy,
//   //         color: Dark.text,
//   //       });
//   //     }
//   //   });
//   // };

//   const searchSymbol = (a) => {
//     if (a.length > 1) {
//       const filteredItems = clientsearch.filter(
//         (item) =>
//           item.username && item.username.toLowerCase().includes(a.toLowerCase())
//       );
//       setClientList(filteredItems);
//     } else {
//       setClientList(clientsearch);
//     }
//   };

//   const stort = (a) => {
//     if (clientlist.length > 0) {
//       if (a == "1") {
//         let data = clientsearch.sort(
//           (a, b) => parseFloat(a.status) - parseFloat(b.status)
//         );
//         setClientList(data);
//       } else if (a == "2") {
//         let data = clientsearch.sort((a, b) =>
//           a.username.localeCompare(b.username)
//         );
//         setClientList(data);
//       } else if (a == "3") {
//         let data = clientsearch.sort((a, b) =>
//           b.username.localeCompare(a.username)
//         );
//         setClientList(data);
//       } else if (a == "4") {
//         let data = clientsearch.sort(
//           (a, b) =>
//             parseFloat(a.deposit_current) - parseFloat(b.deposit_current)
//         );
//         setClientList(data);
//       } else {
//         toast.error("No Options Found", {
//           backgroundColor: Dark.primary,
//           color: Dark.text,
//         });
//       }
//     }
//   };

//   // const loadMore = () => {
//   //   var total = clientlist.length;

//   //   var lower = total - 1;
//   //   var upper = total + 100;
//   //   var clients = [];

//   //   if (clientsearch.length > 0) {
//   //     clientsearch.map((i, t) => {
//   //       if (t > lower && t < upper) {
//   //         clients.push(i);
//   //       }
//   //     });
//   //   }

//   //   setClientList([...clientlist, ...clients]);
//   // };

//   // const handleScroll = (e) => {
//   //   if (
//   //     Math.abs(
//   //       e.target.scrollHeight - e.target.clientHeight - e.target.scrollTop
//   //     ) < 1
//   //   ) {
//   //     // loadMore();
//   //   }
//   // };

// const columns = [
//   {
//     name: "Server",
//     selector: (row) => row.server,
//   },
//   {
//     name: "Username",
//     selector: (row) => row.username,
//   },
//   {
//     name: "Name",
//     selector: (row) => row.name,
//   },
//   {
//     name: "Balance",
//     selector: (row) => row.deposit_current,
//   },
// ];

//   const FilterComponent = ({ filterText, onFilter, onClear }) => (
//     <>
//       <TextField
//         id="search"
//         type="text"
//         placeholder="Filter By Name"
//         aria-label="Search Input"
//         value={filterText}
//         onChange={onFilter}
//       />
//       <ClearButton type="button" onClick={onClear}>
//         X
//       </ClearButton>
//     </>
//   );

//   const subHeaderComponentMemo = React.useMemo(() => {
//     const handleClear = () => {
//       if (filterText) {
//         setResetPaginationToggle(!resetPaginationToggle);
//         setFilterText("");
//       }
//     };

//     return (
//       <FilterComponent
//         onFilter={(e) => setFilterText(e.target.value)}
//         onClear={handleClear}
//         filterText={filterText}
//       />
//     );
//   }, [filterText, resetPaginationToggle]);

//   return (
//     <div>
//       {/* <div
//         style={{
//           height: 50,
//           backgroundColor: Dark.secondary,
//           marginRight: 10,
//           marginLeft: 10,
//           borderRadius: 10,
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//           marginTop: 10,
//         }}
//       >
//         <div
//           onClick={props.ShowSummary}
//           style={{
//             height: 50,
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             width: 50,
//           }}
//         >
//           <RiFileList3Line size={27} color={Dark.text} />
//         </div>
//         <div style={{ flex: 1, padding: 10 }}>
//           <InputText
//             value={search}
//             onChange={(e) => {
//               setSearch(e.target.value);
//               searchSymbol(e.target.value);
//             }}
//             style={{
//               height: 40,
//               backgroundColor: Dark.background,
//               background: Dark.background,
//               borderStyle: "none",
//               width: "100%",
//               color: Dark.text,
//             }}
//             placeholder="Search Client,Username"
//           />
//         </div>
//         <div
//           style={{
//             height: 50,
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             width: 50,
//           }}
//         >
//           <div class="dropdownfilter">
//             <button class="dropbtnfilter">
//               <BiFilterAlt size={30} color={Dark.primary} />
//             </button>
//             <div class="dropdown-contentfilter">
//               <div
//                 className="link"
//                 onClick={() => {
//                   stort("1");
//                   setkey(!keys);
//                 }}
//               >
//                 ACTIVE
//               </div>
//               <div
//                 className="link"
//                 onClick={() => {
//                   stort("2");
//                   setkey(!keys);
//                 }}
//               >
//                 A-Z
//               </div>
//               <div
//                 className="link"
//                 onClick={() => {
//                   stort("3");
//                   setkey(!keys);
//                 }}
//               >
//                 Z-A
//               </div>
//               <div
//                 className="link"
//                 onClick={() => {
//                   stort("4");
//                   setkey(!keys);
//                 }}
//               >
//                 BALANCE
//               </div>
//             </div>
//           </div>
//         </div>

//         <div
//           onClick={props.Add}
//           style={{
//             height: 50,
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             width: 50,
//           }}
//         >
//           <IoMdAddCircle size={35} color={Dark.buy} />
//         </div>

//         {clientlist.length > 0 ? (
//           <div
//             style={{
//               height: 50,
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               width: 50,
//             }}
//           >
//             <PDFDownloadLink
//               style={{
//                 textDecoration: "none",
//               }}
//               document={<ClientPdf client={clientlist} />}
//               fileName={"Client-List"}
//             >
//               {({ blob, url, loading, error }) => (
//                 <div style={{ color: Dark.text }}>
//                   <BsFilePdf size={30} color={Dark.text} />
//                 </div>
//               )}
//             </PDFDownloadLink>
//           </div>
//         ) : null}
//       </div> */}

// <div
//   key={keys}
//   // onScroll={handleScroll}
//   className="scroll"
//   style={{
//     margin: 10,
//     height: "78vh",
//     overflowY: "scroll",
//   }}
// >
//         {loading ? (
//           <div
//             style={{
//               height: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <Loading type="spin" color={Dark.text} height={35} width={35} />
//           </div>
//         ) : clientlist.length > 0 ? (
//           // <VirtualScroller
//           //   items={clientlist}
//           //   itemSize={30}
//           //   itemTemplate={(item, index) => {
//           //     return (
//           //       <ClientCard
//           //         item={item}
//           //         client={props.client}
//           //         setClient={(a) => props.setClient(a)}
//           //       />
//           //     );
//           //   }}
//           //   className="border-1 surface-border border-round"
//           // />

//           <DataTable
//             title="Client"
//             columns={columns}
//             data={clientlist}
//             pagination
//             onColumnOrderChange={(cols) => console.log(cols)}
//           />
//         ) : (
//           <div
//             style={{
//               height: "100%",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               color: Dark.text,
//             }}
//           >
//             NO CLIENT FOUND.
//           </div>
//         )}
//       </div>
//     </div>
//   );
// }

import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { toast } from "react-toast";
import styled from "styled-components";
import Backend from "../../../../Backend/Backend";
import { Dark } from "../../../../Theme/Theme";

const backend = new Backend();

export default function Client(props) {
  const [loading, setLoading] = useState("");
  const [clientlist, setClientList] = useState([]);
  const [clientsearch, setClientSearch] = useState([]);

  const [filterText, setFilterText] = React.useState("");
  const [resetPaginationToggle, setResetPaginationToggle] = useState(false);

  useEffect(() => {
    loadClient();
  }, []);

  const loadClient = () => {
    setLoading(true);
    let data = {
      token: localStorage.getItem("token"),
      id: localStorage.getItem("id"),
      username: localStorage.getItem("username"),
      server: localStorage.getItem("server"),
    };

    backend.load_client(data).then((r) => {
      setLoading(false);
      if (r.error == "False") {
        setClientList(r.client);
        setClientSearch(r.client);
      } else {
        toast.error(r.message, {
          backgroundColor: Dark.sell,
          color: Dark.text,
        });
      }
    });
  };

  const filteredItems = clientlist.filter(
    (item) =>
      item.username &&
      item.username.toLowerCase().includes(filterText.toLowerCase())
  );
  const subHeaderComponentMemo = React.useMemo(() => {
    const handleClear = () => {
      if (filterText) {
        setResetPaginationToggle(!resetPaginationToggle);
        setFilterText("");
      }
    };
    return (
      <FilterComponent
        onFilter={(e) => setFilterText(e.target.value)}
        onClear={handleClear}
        filterText={filterText}
        ShowSummary={props.ShowSummary}
        Add={props.Add}
      />
    );
  }, [filterText, resetPaginationToggle]);

  const columns = [
    {
      name: "Server",
      selector: (row) => row.server,
      conditionalCellStyles: [
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
      ],
    },
    {
      name: "Username",
      selector: (row) => row.username,
      conditionalCellStyles: [
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
      ],
    },
    {
      name: "Name",
      selector: (row) => row.name,
      conditionalCellStyles: [
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
      ],
    },
    {
      name: "Balance",
      selector: (row) => row.deposit_current,
      conditionalCellStyles: [
        {
          when: (row) => row.deposit_current > 0,
          style: {
            color: Dark.buy,
          },
        },
        {
          when: (row) => row.deposit_current < 0,
          style: {
            color: Dark.sell,
          },
        },
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
      ],
    },
    {
      name: "M-FUT",
      selector: (row) => row.multiplier_future,
      conditionalCellStyles: [
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
      ],
    },
    {
      name: "M-MCX",
      selector: (row) => row.multiplier_mcx,
      conditionalCellStyles: [
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
      ],
    },
    {
      name: "M-OPT",
      selector: (row) => row.multiplier_options,
      conditionalCellStyles: [
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
      ],
    },
    {
      name: "FUTURE",
      selector: (row) => (row.future == 1 ? "ACTIVE" : "DEACTIVE"),
      conditionalCellStyles: [
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
        {
          when: (row) => row.future == 1,
          style: {
            color: Dark.buy,
          },
        },
        {
          when: (row) => row.future == 0,
          style: {
            color: Dark.sell,
          },
        },
      ],
    },
    {
      name: "MCX",
      selector: (row) => (row.mcx == 1 ? "ACTIVE" : "DEACTIVE"),
      conditionalCellStyles: [
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
        {
          when: (row) => row.mcx == 1,
          style: {
            color: Dark.buy,
          },
        },
        {
          when: (row) => row.mcx == 0,
          style: {
            color: Dark.sell,
          },
        },
      ],
    },
    {
      name: "OPTIONS",
      selector: (row) => (row.options == 1 ? "ACTIVE" : "DEACTIVE"),
      conditionalCellStyles: [
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
        {
          when: (row) => row.options == 1,
          style: {
            color: Dark.buy,
          },
        },
        {
          when: (row) => row.options == 0,
          style: {
            color: Dark.sell,
          },
        },
      ],
    },
    {
      name: "F-LIMIT",
      selector: (row) => (row.fresh_trade == 1 ? "ACTIVE" : "DEACTIVE"),
      conditionalCellStyles: [
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
        {
          when: (row) => row.fresh_trade == 1,
          style: {
            color: Dark.buy,
          },
        },
        {
          when: (row) => row.fresh_trade == 0,
          style: {
            color: Dark.sell,
          },
        },
      ],
    },
    {
      name: "MID-LIMIT",
      selector: (row) => (row.mid_limit == 1 ? "ACTIVE" : "DEACTIVE"),
      conditionalCellStyles: [
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
        {
          when: (row) => row.mid_limit == 1,
          style: {
            color: Dark.buy,
          },
        },
        {
          when: (row) => row.mid_limit == 0,
          style: {
            color: Dark.sell,
          },
        },
      ],
    },
    {
      name: "STATUS",
      selector: (row) => (row.status == 1 ? "ACTIVE" : "DEACTIVE"),
      conditionalCellStyles: [
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
        {
          when: (row) => row.status == 1,
          style: {
            color: Dark.buy,
          },
        },
        {
          when: (row) => row.status == 0,
          style: {
            color: Dark.sell,
          },
        },
      ],
    },
    {
      name: "EDIT",
      button: true,
      conditionalCellStyles: [
        {
          when: (row) => row.id == props.client.id,
          style: {
            backgroundColor: Dark.secondary,
          },
        },
      ],
      cell: (row) => 
        <button onClick={()=>props.setClient(row)}>EDIT USER</button>
      
    },
    
  ];

  return (
    <div>
      <div
        className="scroll"
        style={{
          margin: 10,
          height: "78vh",
          overflowY: "scroll",
        }}
      >
        <DataTable
          theme="dark"
          highlightOnHover
          columns={columns}
          data={filteredItems}
          pagination
          paginationResetDefaultPage={resetPaginationToggle} // optionally, a hook to reset pagination to page 1
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
        />
      </div>
    </div>
  );
}

const TextField = styled.input`
  height: 32px;
  width: 200px;
  border-radius: 3px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border: 1px solid #e5e5e5;
  padding: 0 32px 0 16px;

  &:hover {
    cursor: pointer;
  }
`;

const ClearButton = styled.button`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  height: 34px;
  width: 32px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const FilterComponent = ({ filterText, onFilter, onClear,ShowSummary, Add }) => (
  <>
    <div
      onClick={ShowSummary}
      style={{
        height: 40,
        width: 100,
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        color: Dark.text,
        marginRight: 20,
      }}
    >
      SUMMARY
    </div>

    <div
      onClick={Add}
      style={{
        height: 40,
        width: 100,
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        color: Dark.text,
        marginRight: 20,
      }}
    >
      ADD USER
    </div>

    <TextField
      id="search"
      type="text"
      placeholder="Filter By Name"
      aria-label="Search Input"
      value={filterText}
      onChange={onFilter}
    />

    <ClearButton type="button" onClick={onClear}>
      X
    </ClearButton>

    <div
      // onClick={() => setType(3)}
      style={{
        height: 40,
        width: 100,
        backgroundColor: Dark.sell,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: 10,
        color: Dark.text,
        marginLeft: 20,
      }}
    >
      CLEAR
    </div>
  </>
);
