import React, { useState } from "react";
import { Dark } from "../../../Theme/Theme";
import Clients from "./Client/Client";
import ClientList from "./ClientList/ClientList";
import AddClient from "./AddClient/AddClient";
import Summary from "./Summary/Summary";
export default function Client() {
  const height = window.innerHeight;
  const [type, setType] = useState(0);
  const [client, setClient] = useState("");
  const [update, setupdate] = useState(0);

  const AddNewClient = () => {
    setType(1);
  };
  const ShowSummary = () => {
    setType(4);
  };
  const ShowClientList = () => {
    setType(0);
  };

  return (
    <div
      style={{
        height: "100%",
        height: height / 1.24,
        display: "flex",
      }}
    >
      {type == 0 ? (
        <div style={{ flex: 1, backgroundColor: Dark.background }}>
          <Clients
            Add={AddNewClient}
            ShowSummary={ShowSummary}
            setClient={(a) => {
              setClient(a);
              setType(2);
            }}
            key={update}
            client={client}
          />
        </div>
      ) : (
        <div style={{ flex: 3, margin: 10 }}>
          {type == 1 ? (
            <AddClient
              ShowClientList={ShowClientList}
              reload={() => {
                setupdate(1);
                ShowSummary();
              }}
            />
          ) : null}

          {type == 2 ? (
            <ClientList
            ShowClientList={ShowClientList}
              client={client}
              reload={() => {
                setupdate(1);
              }}
            />
          ) : null}

          {type == 4 ? <Summary ShowClientList={ShowClientList} /> : null}
        </div>
      )}
    </div>
  );
}
