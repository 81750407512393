import React, { useContext, useState } from "react";
import { Dark } from "../../../../Theme/Theme";
// import { PDFDownloadLink, PDFViewer } from "@react-pdf/renderer";
// import Pdf from "./Pdf/Pdf";
import moment from "moment";
import SocketContext from "../../../../Healer/SocketContect";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toast";
import { NumericFormat } from "react-number-format";
export default function SummaryCard(props) {
  const total = useSelector((state) => state.total);
  const btotal = useSelector((state) => state.btotal);
  let m2m = parseFloat(total) + parseFloat(btotal);
  const item = props.item;
  const socket = useContext(SocketContext);
  const dispatch = useDispatch();

  const Update = () => {
    if (item.standing.length > 0) {
      props.updateid(item.id);
    } else {
      toast.error("No Standing Found !", {
        backgroundColor: Dark.buy,
        color: Dark.text,
      });
    }
  };

  return (
    <div
      style={{
        height: "55px",
        width: "100%",
        backgroundColor: Dark.background,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: 12,
        borderStyle: "solid",
        borderWidth: 0,
        borderBottomWidth: 1,
        borderBottomColor: Dark.primary,
      }}
    >
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        {item.broker_name}
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: item.profit_loss_week > 0 ? Dark.buy : Dark.sell,
        }}
      >
        <NumericFormat
          value={item.profit_loss_week == null || item.profit_loss_week == 0 || item.profit_loss_week == undefined ? 0 : item.profit_loss_week.toFixed(0)}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.sell,
        }}
      >
        <NumericFormat
          value={item.brokerage_week == null || item.brokerage_week == 0 || item.brokerage_week == undefined ? 0 : item.brokerage_week.toFixed(0)}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <NumericFormat
          value={item.brokerage_broker_week_fut == null || item.brokerage_broker_week_fut == 0 || item.brokerage_broker_week_fut == undefined ? 0 : item.brokerage_broker_week_fut.toFixed(0)}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <NumericFormat
          value={item.brokerage_broker_week_mcx == null || item.brokerage_broker_week_mcx == 0 || item.brokerage_broker_week_mcx == undefined ? 0 : item.brokerage_broker_week_mcx.toFixed(0)}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <NumericFormat
          value={item.brokerage_broker_week_options == null || item.brokerage_broker_week_options == 0 || item.brokerage_broker_week_options == undefined ? 0 : item.brokerage_broker_week_options.toFixed(0)}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          color: Dark.text,
        }}
      >
        <NumericFormat
          value={item.brokerage_broker_week == null || item.brokerage_broker_week == 0 || item.brokerage_broker_week == undefined ? 0 : item.brokerage_broker_week.toFixed(0)}
          displayType={"text"}
          thousandSeparator={true}
        />{" "}
        ₹
      </div>
      {/* <div
        style={{
          flex: 1,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          onClick={Update}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: 8,
            backgroundColor: props.id == item.id ? Dark.primary : Dark.sell,
            width: 100,
            borderRadius: 10,
            color: Dark.text,
          }}
        >
          {props.id == item.id ? (
            <PDFDownloadLink
              style={{
                textDecoration: "none",
              }}
              document={
                <Pdf
                  brokerage_broker_week={item.brokerage_broker_week}
                  brokerage_week={item.brokerage_week}
                  profit_loss_week={item.profit_loss_week}
                  profit_loss={total}
                  m2m={m2m}
                  btotal={btotal}
                  dispatch={dispatch}
                  socket={socket}
                  username={item.username}
                  name={item.name}
                  start={moment().format("DD-MM-YYYY")}
                  end={moment().format("DD-MM-YYYY")}
                  standing={item.standing}
                />
              }
              fileName={`${item.username}(${item.name}) `}
            >
              {({ blob, url, loading, error }) => (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div style={{ color: Dark.text, fontSize: 12 }}>
                    {" "}
                    STANDING
                  </div>
                  <div
                    style={{
                      color: total > 0 ? Dark.buy : Dark.sell,
                      fontSize: 10,
                    }}
                  >
                    {" "}
                    {parseFloat(total).toFixed(2)}
                  </div>
                </div>
              )}
            </PDFDownloadLink>
          ) : (
            <div style={{ color: Dark.text }}> STANDING</div>
          )}
        </div>
      </div> */}
    </div>
  );
}
